import React from 'react'
import { Text, TextProps } from 'grommet';
import styled, { keyframes } from 'styled-components'


interface AnimatableTextProps extends TextProps {
  animate?: any
}
interface Props extends AnimatableTextProps {
  initialTime: number,
  onComplete: () => void
}
export default function Countdown({ animate, initialTime, onComplete, ...props }: Props) {
  const [timer, setRemainingTime] = React.useState(initialTime);
  const shouldUpdate = React.useRef(true)

  React.useEffect(() => {
    shouldUpdate.current = true

    if (timer === 0) {
      onComplete()
    } else {
      setTimeout(() => {
        if (shouldUpdate.current && timer > 0) {
          setRemainingTime(timer - 1)
        }
      }, 1000)
    }

    return function cleanup() {
      shouldUpdate.current = false
    }
  })

  if (animate) {
    return <AnimatedText {...props}>{timer}</AnimatedText>
  }
  return <Text {...props}>{timer}</Text>
}

const opacityPulse = keyframes`
  0% {opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {opacity: 0.0;}
`

const AnimatedText = styled(Text)`
    animation: ${opacityPulse} 1.10s ease-out;
    animation-iteration-count: infinite; 
    opacity: 1;  
`
