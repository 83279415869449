import React from 'react'
import styled from 'styled-components'
import { Box, Button, FormField as GrommetFormField, Form, FormFieldProps } from 'grommet'
import { Text } from '../../common'
import validator from 'email-validator'
import Input, { Dropdown } from './components'
import options from './options.json'
import { useHistory } from 'react-router'
import firebase, { DocumentData } from '../../../firebase'


const FormField = styled(GrommetFormField)
  .attrs<FormFieldProps>({
    margin: { top: 'medium', bottom: 'medium' },
    contentProps: { border: undefined }
  })``
async function checkEmail(email: string): Promise<DocumentData> {
  if (!validator.validate(email)) {
    throw new Error("invalid email")
  }

  const doc = await firebase.lookupEmail(email)
  if (!doc) {
    throw new Error("user does not exist")
  }

  return doc
}



export default function Registration() {
  const history = useHistory()

  const [first, setFirstName] = React.useState('')
  const [last, setLastName] = React.useState('')
  const [division, setSelectedDivision] = React.useState('');
  const [state, setSelectedState] = React.useState('');

  //#region Email State and Callbacks
  const [email, setEmail] = React.useState('');
  const onEmailChange = React.useCallback((e) => {
    const { currentTarget: { value } } = e;
    setEmail(value.trim());


    checkEmail(value.trim())
      .then((doc) => {
        setFirstName(doc["first_name"])
        setLastName(doc["last_name"])
        setSelectedDivision(doc["division"])
        setSelectedState(doc["state"])
      })
      .catch((e) => { /** silently ignore */ })
  }, [])
  //#endregion


  //#region Submit Callbacks
  const isFormValid = validator.validate(email) && division.length > 0 && state.length > 0 && first.length > 0 && last.length > 0
  const onSubmit = React.useCallback(() => {
    firebase.createOrUpdateUser({ first_name: first, last_name: last, email, division, state })
      .then((ref) => {
        history.replace(`/play?id=${email}`)
      })

  }, [last, first, division, state, email, history])
  //#endregion

  return <Box margin={{ top: "large" }}>
    <Text size="large" weight="bold">
      To play, fill in your name, email, and what division and state you are in so we can accurately rank you on the leaderboards.
      </Text>
    <Form>
      <FormField htmlFor="email_address_id">
        <Input
          type="email"
          onChange={onEmailChange}
          value={email}
          label="Email Address"
        />
      </FormField>

      <FormField htmlFor="first_name_id">
        <Input
          type="text"
          onChange={(e) => { setFirstName(e.currentTarget.value) }}
          value={first}
          label="First Name"
        />
      </FormField>

      <FormField htmlFor="last_name_id" >
        <Input
          type="text"
          onChange={(e) => { setLastName(e.currentTarget.value) }}
          value={last}
          label="Last Name"
        />
      </FormField>

      <FormField >
        <Dropdown label="Division" options={options.divisions} value={division} onChange={({ option }) => { setSelectedDivision(option) }} />
      </FormField>

      <FormField>
        <Dropdown label="State" options={options.states} value={state} onChange={({ option }) => { setSelectedState(option) }} />
      </FormField>

      <div style={{ marginTop: 36 }} />
      <Text>
        Adjust your eyes, straighten your glasses, and get ready with your laser focus for a game of color detection to prove whose eyes are the most honed in on hue.
      </Text>

      <div style={{ marginTop: 18 }} />

      <Button
        style={{ paddingLeft: '12.5%', paddingRight: '12.5%' }}
        primary
        label="Play"
        type="submit"
        onClick={onSubmit}
        disabled={!isFormValid}
      />


    </Form>
  </Box>
}