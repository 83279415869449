import theme from '../theme';
import _ from 'lodash';
import Color from 'color';

function component2Hex(c: number) {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
const Colors = {
  hard: [
    'aqua',
    'cyan',
    'blue',
    'blue2',
    'darkblue',
    'darkred',
    'red',
    'red2',
    'red3',
    'greenish',
    'green',
    'lime',
    'fuchsia',
    'magenta',
    'gold',
    'yellow',
    'lemon',
    'cheese',
    'pinky',
    'pinkish',
    'darkenedpink',
  ],
  get normal() {
    return _.filter(_.keys(this.names), (x) => !this.hard.includes(x));
  },

  names: {
    greenish: '#E4EAE9',
    aqua: '#00ffff',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    // black: '#000000',
    blue: '#0000ff',
    blue2: '#2A00FF',
    brown: '#a52a2a',
    burntorange: '#FF5733',
    cheese: '#ffa6000',
    cyan: '#00ffff',
    darkenedpink: '#6B0F5C',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgrey: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkviolet: '#9400d3',
    fuchsia: '#ff00ff',
    gold: '#ffd700',
    greenest: '#34C51B',
    green: '#008000',
    indigo: '#4b0082',
    khaki: '#f0e68c',
    lakeblue: '#5a8ac6',
    lemon: '#FFFB00',
    lightblue: '#add8e6',
    lightcyan: '#e0ffff',
    lightgreen: '#90ee90',
    lightgrey: '#d3d3d3',
    lightpink: '#ffb6c1',
    lightsalmon: '#fa9573',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    magenta: '#ff00ff',
    maroon: '#800000',
    navy: '#000080',
    olive: '#808000',
    orange: '#ffa500',
    pastelred: '#f8696b',
    pink: '#ffc0cb',
    pinky: '#CA65BA',
    pinkish: '#DECADB',
    purple: '#800080',
    violet: '#800080',
    red: '#ff0000',
    red2: '#ff0505',
    red3: '#ff0a0a',
    silver: '#c0c0c0',
    yaleblue: '#135296',
    // white: '#ffffff',
    yellow: '#fefe01',
  } as { [k: string]: string },

  contrastingHex(c: string): string {
    const useLightColor = Color(c).isDark();
    if (useLightColor) {
      return theme.global.colors['light-1'] || 'white';
    }
    return theme.global.colors['dark-2'] || 'black';
  },

  random(hardColorsOnly: boolean): string {
    var result;
    var count = 0;
    let keys: string[];
    if (hardColorsOnly) {
      keys = Colors.hard;
    } else {
      keys = this.normal;
    }

    for (var prop of keys) {
      if (Math.random() < 1 / ++count) {
        result = prop;
      }
    }

    console.log('randomly selected color name ', result);

    if (result) {
      return this.names[result];
    }

    return this.names[0];
  },

  toHex(r: number, g: number, b: number) {
    return '#' + component2Hex(r) + component2Hex(g) + component2Hex(b);
  },

  shouldDarken() {
    return Math.round(Math.random() * 10) > 5;
  },
  alter(val: number, diff: number) {
    const r = Math.round(Math.random() * 100);
    if (r > 5) {
      return diff + val;
    }

    return val - diff;
  },

  calcColorDiff(
    val: string,
    round: number,
    maxRounds: number,
    difficultyMultipler: number,
  ) {
    // follow y's def to get meaning.
    const y = maxRounds;
    const r = round;
    let diff = (y - (r * r) / y) / (difficultyMultipler * 1.2 * y);
    let color = Color(val);

    const percentComplete = r / y;

    console.log(diff);
    if (this.shouldDarken()) {
      color = color.darken(diff);
    } else {
      color = color.lighten(diff);
    }

    if (percentComplete < 0.35) {
      color = color.saturate(diff / (1 - percentComplete));
    }

    if (percentComplete < 0.5) {
      color = color.fade(diff);
    }

    return color.hex();
  },
};

export default Colors;
