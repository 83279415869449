import React from "react";
import { Box, Main, Text } from "grommet";
import moment from "moment";
import styled from "styled-components";
import "./App.css";
import { Game, Leaderboard, Home } from "./components/";
import { Route, Switch } from "react-router";

const ContentBox = styled(Box)`
  align-items: center;

  max-width: ${1268 + 24}px;

  overflow-x: auto;
  overflow-y: scroll;

  width: 100vw;
  height: 100%;

  margin: 24px;
`;

function EnforceContestTimes({ children }: { children?: React.ReactNode }) {
  const now = moment();
  if (now.isAfter(contest.end)) {
    window.location.href = "https://variableinc.com"
    return null;
  }

  if (now.isBefore(contest.start)) {
    alert(`Contest has not started, please check back ${contest.start.format("dddd, MMMM Do, hh:mm A")}`)
    window.location.href = "https://variableinc.com"
    return null
  }

  return <>
    {children}
  </>
}
function App() {

  return (
    <Main align="center">
      {/* <EntryTimeRemaining /> */}

      <Switch>
        <Route path="/play">
          <EnforceContestTimes>
            <Game />
          </EnforceContestTimes>
        </Route>

        <Route path="/leaderboard">
          <ContentBox
            pad="medium"
            justify="center"
            align="center"
            overflow="scroll"
          >
            <Leaderboard />
          </ContentBox>
        </Route>

        <Route path="*">
          <EnforceContestTimes>
            <ContentBox
              pad="medium"
              justify="center"
              align="center"
              overflow="scroll"
            >
              <Home />
            </ContentBox>
          </EnforceContestTimes>
        </Route>
      </Switch>
    </Main>
  );
}

const contest = {
  end: moment("3/10/2023 10:00 PM EST"),
  start: moment("12/20/2022 6:00 AM EST")
}

function EntryTimeRemaining() {
  return (
    <Box
      background="accent-2"
      pad="xsmall"
      height="xsmall"
      width="100vw"
      align="center"
      justify="center"
    >
      <Text size="medium" textAlign="center" color="light-4" weight="bold">
        Entries accepted until {contest.end.format("dddd, MMMM Do, hh:mm A")}
      </Text>
    </Box>
  );
}

export default App;
