export function humanize(number: number) {
  if (number % 100 >= 11 && number % 100 <= 13)
    return numberWithCommas(number) + 'th';

  switch (number % 10) {
    case 1:
      return numberWithCommas(number) + 'st';
    case 2:
      return numberWithCommas(number) + 'nd';
    case 3:
      return numberWithCommas(number) + 'rd';
  }

  return numberWithCommas(number) + 'th';
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// ('week of 2/17/2021');
// ('week of 2/21/2021');
