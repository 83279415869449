const data = {
  items: [
    {
      title: 'Pro Tip',
      descr: 'Every user has one skip per game.',
    },
    {
      title: 'Fun Fact',
      descr: 'The human eye and brain perceive only the reflected colors.',
    },
    {
      title: 'Pro Tip',
      descr: 'The faster you answer, the more points awarded for the round.',
    },
    {
      title: 'Did you know?',
      descr: 'Paint was one of the earliest inventions of humanity. ',
    },
    {
      title: 'Fun Fact',
      descr:
        'The color purple was once associated with royalty. At one time only aristocrats could afford the expensive pigment. ',
    },
    {
      title: 'Fun Fact',
      descr:
        'Francis Davis Millet is credited with the invention of spray paint while working under tight deadlines to complete construction for the Chicago World’s Fair.',
    },
    {
      title: 'Did you know?',
      descr:
        'Did you know that various paint colors can help the body heal itself? For example, red often helps depressed people regain vigor. And green has been shown to relieve stress.',
    },
  ],
};
export default data;
