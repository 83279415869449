import _ from 'lodash';

export default class Tile {
  color: string;
  position: number;

  id: string;
  constructor(color: string, pos: number) {
    this.color = color;
    this.position = pos;

    this.id = _.uniqueId('tile_');
  }
}
