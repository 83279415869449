import * as React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  CardHeader,
  CardBody,
  Image,
} from "grommet";

import DataTable from "./DataTable";
import firebase from "../../firebase";
import { GameRank } from "../../models";

import { Spinner } from "./components";
import { useCurrentEmail } from "../../utils/hooks";
import { useHistory, useLocation } from "react-router";
import { humanize, numberWithCommas } from '../../utils'



function share(name: string, bestScore: number) {
  return () => {
    const body = `How good are your eyes at telling the difference between two colors? Play to win an iPad Air, today. My personal best score is ${numberWithCommas(bestScore)}!`
    const subject = `${name} invites you to a challenge!`

    if (navigator.share) {
      navigator.share({
        title: subject,
        text: body,
        url: window.location.hostname
      }).then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      window.location.href = `mailto:?subject = ${encodeURIComponent(subject)} & body=${encodeURIComponent(body)}`
    }
  }
}
export default React.memo(() => {
  const [rankings, setRankings] = React.useState<GameRank | null>(null);
  const email = useCurrentEmail();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    if (!email) {
      history.replace("/");
      return;
    }

    firebase.calculateRankings(email).then(setRankings);
  }, [email, setRankings, history]);

  if (!rankings) {
    return (
      <Box justify="center" align="center">
        <Heading>Loading Results</Heading>
        <Spinner />
      </Box>
    );
  }

  const r: GameRank = rankings;
  return (
    <Box>
      <Box height={{ max: "48px", min: "48px" }} id="logo_box" margin="large">
        <Image
          margin="auto"
          height="48px"
          src="https://s3.amazonaws.com/colorcloud.io/product-images/Variable_logo_alt_01.png"
          fit="contain"
        />
      </Box>
      <Box
        width="100%"
        round
        margin={{ bottom: "medium" }}
        height={{ max: "xxlarge", min: "large" }}
      >
        <CardHeader
          background="accent-3"
          height="medium"
          align="center"
          justify="center"
          direction="column"
        >
          <Box
            margin="small"
            height="256px"
            width="256px"
            background="white"
            style={{ borderRadius: "50%" }}
            align="center"
            justify="center"
          >
            <Text color="accent-2" size="xlarge" margin="4px">
              You Scored
            </Text>
            <Heading level={2} color="accent-2" margin="4px">
              {numberWithCommas(r.user_rank.latest_score)}
            </Heading>
            <Text color="accent-2" size="xlarge" margin="4px">
              PRO
            </Text>
          </Box>
        </CardHeader>
        <CardBody pad="large" background="accent-2">
          <Heading
            margin="0px"
            level={2}
            color="light-1"
          >{`${r.user.first_name}'s Rank:`}</Heading>
          < Box
            pad="0px"
            margin={{ left: "xxsmall" }}
            justify="start"
            direction="row"
            align="center"
          >
            <Text
              size="small"
              color="light-1"
              style={{
                paddingRight: 8,
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              PERSONAL BEST:{" "}
            </Text>
            <Text size="small" color="light-1" margin="0px" weight="bold">
              {numberWithCommas(r.user_rank.best)}
            </Text>
          </Box >

          <Box
            id="user_ranking"
            direction="row"
            height="xsmall"
            color="light-1"
          >
            <Box pad="large" fill justify="center">
              <Heading level={4} color="light-1" margin="0px">
                OVERALL
              </Heading>
              <Heading level={2} color="light-1" margin="0px">
                {humanize(r.user_rank.overall)}
              </Heading>
            </Box>

            <Box pad="large" fill justify="center">
              <Heading level={4} color="light-1" margin="0px">
                DIVISION
              </Heading>
              <Heading level={2} color="light-1" margin="0px">
                {humanize(r.user_rank.division)}
              </Heading>
            </Box>

            <Box pad="large" fill justify="center">
              <Heading level={4} color="light-1" margin="0px">
                STATE
              </Heading>
              <Heading level={2} color="light-1" margin="0px">
                {humanize(r.user_rank.state)}
              </Heading>
            </Box>
          </Box>



          {/* <Button
            primary
            label="Challenge Friends"
            style={{ paddingTop: 12, paddingBottom: 12 }}
            margin={{ top: "medium" }}
            onClick={share(r.user.first_name, r.user.best_score || 0)}
          /> */}

          <Button
            margin={{ top: "medium" }}
            color="light-1"
            label="Register"
            style={{ paddingTop: 12, paddingBottom: 12 }}
            onClick={() => {
              history.replace(`/`);
            }}
          />
        </CardBody >
      </Box >

      <DataTable leaders={r} />
    </Box >
  );
});
