import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom'
import { Grommet } from 'grommet'
import theme from './theme'

import firebase from './firebase'


function InitializeApp() {

  if (!firebase.app) {
    firebase.initialize()
  }

  return <React.StrictMode>
    <BrowserRouter>
      <Grommet theme={theme} themeMode="dark" full>
        <App />
      </Grommet>
    </BrowserRouter>
  </React.StrictMode>
}


ReactDOM.render(
  <InitializeApp />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
