'use-strict'
import React from 'react'
import { Box } from 'grommet'
import { Tile } from '../datamodel'


export interface Props {
  tile: Tile
  onClick: (t: Tile) => void
}

function ColorCard({ tile, onClick }: Props) {
  const { color } = tile
  const [isHovering, setHovering] = React.useState(false)
  const onClickCallback = React.useCallback(() => { onClick(tile) }, [tile, onClick])

  return (
    <Box
      onClick={onClickCallback}
      onMouseLeave={() => setHovering(false)}
      onMouseEnter={() => setHovering(true)}
      animation={isHovering ? "pulse" : undefined}
      style={{
        width: '100%',
        height: '100%',
      }}
      round="small"
      background={color}
    />
  )
}


interface GridProps {
  tiles: ReadonlyArray<Tile>,

  onItemClick: (t: Tile) => void
}
export function ColorCardGrid({ tiles, onItemClick }: GridProps) {
  return <>
    {tiles.map((tile) => {
      return <ColorCard key={tile.id} tile={tile} onClick={onItemClick} />
    })}
  </>
}
