import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import _ from 'lodash';

import { Game } from './components/Game/datamodel';
import { User, GameRank } from './models';
const settings = {} as firebase.default.firestore.Settings;

const config = {
  apiKey: 'AIzaSyAUxMpyHAPPASWX9Yw5F8zAmUg9y-7y5Xo',
  authDomain: 'sherwin-color-game.firebaseapp.com',
  projectId: 'sherwin-color-game',
  storageBucket: 'sherwin-color-game.appspot.com',
  messagingSenderId: '26790053263',
  appId: '1:26790053263:web:3a02ee4ed92952235177fa',
};

export type DocRef = firebase.default.firestore.DocumentReference;
export type DocumentData = firebase.default.firestore.DocumentData;

interface iFirebase {
  app: firebase.default.app.App;
  firestore: firebase.default.firestore.Firestore;
  lookupEmail: (email: string) => Promise<undefined | User>;

  createOrUpdateUser(user: User): Promise<DocRef>;

  saveGameResult(game: Game): Promise<void>;

  initialize(): Promise<void>;

  calculateRankings(email: string): Promise<GameRank>;
}

export default {
  async createOrUpdateUser(user: User) {
    const doc = this.firestore.collection('users').doc(user.email);

    const ref = await doc.get();
    if (ref && ref.exists) {
      const copyOf = _.clone(user);
      delete copyOf.best_score;
      await ref.ref.set(
        { ...copyOf, updated_at: new Date().toISOString() },
        { merge: true },
      );
    } else {
      await doc.set(
        {
          ...user,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
        { merge: true },
      );
    }

    return doc;
  },

  async calculateRankings(email: string): Promise<GameRank> {
    const user = await this.lookupEmail(email);
    if (!user) {
      throw new Error('game broke');
    }
    const usersResults = await this.firestore
      .collection('users')
      .doc(email)
      .collection('results')
      .orderBy('created_at', 'desc')
      .get();

    let latest_score = 0;
    if (!usersResults.empty) {
      latest_score = usersResults.docs[0].data()['score'];
    }

    const ranking = (
      await this.firestore
        .collection('users')
        .orderBy('best_score', 'desc')
        .get()
    ).docs.map((userRef, index) => {
      return { ...(userRef.data() as User), rank: index + 1 };
    });

    let ranks = ranking.map((x) => ({
      ...x,
      name: x.first_name + ' ' + x.last_name,
      score: x.best_score || 0,
    }));

    const divisions = _.chain(ranking).groupBy('division');
    const states = _.chain(ranking).groupBy('state');

   
    return {
      user,

      user_rank: {
        latest_score,

        best: user.best_score || 0,

        overall: ranks.findIndex((x) => x.email === email) + 1,

        state:
          states
            .find((value: User[], key: string) => key === user.state)
            .sort((a, b) => (b.best_score as number) - (a.best_score as number))
            .findIndex((x) => x.email === email)
            .value() + 1,

        division:
          divisions
            .find((value: User[], key: string) => key === user.division)
            .sort((a, b) => (b.best_score as number) - (a.best_score as number))
            .findIndex((x) => x.email === email)
            .value() + 1,
      },

      overall: ranks,

      divisions: divisions
        .map((value: User[], key: string) => ({
          name: key,
          max:
            _.maxBy(value, ({ best_score }) => best_score || 0)?.best_score ||
            0,
          score: Math.round(
            _.meanBy(value, ({ best_score }) => best_score || 0),
          ),
        }))
        .value()
        .sort((a, b) =>  b.score - a.score)
        .map((x, i) => ({ ...x, rank: i + 1 })),

      states: states
        .map((value: User[], key: string) => ({
          name: key,
          max:
            _.maxBy(value, ({ best_score }) => best_score || 0)?.best_score ||
            0,
          score: Math.round(
            _.meanBy(value, ({ best_score }) => best_score || 0),
          ),
        }))
        .value()
        .sort((a, b) =>  b.score - a.score)
        .map((x, i) => ({ ...x, rank: i + 1 })),
    };
  },

  async lookupEmail(email: string): Promise<User | undefined> {
    try {
      const snap = await this.app
        .firestore()
        .collection('users')
        .doc(email)
        .get();

      if (snap.exists) {
        return snap.data() as User | undefined;
      }
    } catch (err) {
      console.log(err);
    }

    return undefined;
  },

  async saveGameResult(game: Game) {
    await this.firestore
      .collection('users')
      .doc(game.user.email)
      .collection('results')
      .add({
        score: game.score,
        round: game.round,
        did_complete: game.round === Game.MaxRounds,
        created_at: new Date().toISOString(),
      });

    const game_play_results = await this.firestore
      .collection('users')
      .doc(game.user.email)
      .collection('results')
      .orderBy('score', 'desc')
      .get();

    const { score } = game_play_results.docs[0].data();
    this.firestore
      .collection('users')
      .doc(game.user.email)
      .set({ best_score: score }, { merge: true });
  },

  async initialize() {
    this.app = firebase.default.initializeApp(config);
    this.firestore = firebase.default.firestore(this.app);
    this.firestore.settings(settings);

    const userCreds = await firebase.default.auth().signInAnonymously();
  },
} as iFirebase;
