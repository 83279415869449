import React from 'react'
import { Card, CardHeader, CardBody, DataTable as DT, Box, Text } from 'grommet'
import { Spinner, Tabs, Tab } from './components'
import { GameRank, AggregateResult } from '../../models'

export interface Props {
  leaders: GameRank | null
}

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

type TabId = "divisions" | "overall" | "states"
interface TabSelectorProps {
  selected: TabId,
  onClick: (id: TabId) => void
}
function TabSelector({ selected, onClick }: TabSelectorProps) {
  return <Tabs>
    <Tab selected={selected === "overall"} onClick={() => onClick("overall")}>
      <Text size="small" weight="bold">OVERALL</Text>
    </Tab>

    <Tab selected={selected === "divisions"} onClick={() => onClick("divisions")}>
      <Text size="small" weight="bold">DIVISION</Text>
    </Tab>

    <Tab selected={selected === "states"} onClick={() => onClick("states")}>
      <Text size="small" weight="bold">STATE</Text>
    </Tab>
  </Tabs >
}

export default function DataTable({ leaders }: Props) {
  const [selectedTab, setSelectedTab] = React.useState<TabId>("overall")
  if (!leaders) {
    return null
  }

  return <Box round="large" direction="column" height={{ max: 'xlarge', min: 'large' }}>
    <CardHeader background="accent-2" pad="medium">
      <TabSelector selected={selectedTab} onClick={setSelectedTab} />
    </CardHeader>
    <CardBody>
      <DT
        size="medium"
        data={leaders[selectedTab]}
        columns={overallConfig.columns}
        onClickRow={() => { }}
        background={{ header: 'accent-2', body: ['#FFFFFF', '#EFEFEF'] }}
      />

      {!leaders && <Spinner />}
    </CardBody>
  </Box >
}


function Cell(props: { value: string, align: 'start' | 'center' | 'end' | 'baseline' | 'stretch' }) {
  return <Box pad="xsmall" align={props.align}>
    <Text color="dark-2">{props.value}</Text>
  </Box>
}
const overallConfig = {
  columns: [
    {
      property: "rank",
      header: undefined,
      primary: true,
      render: (datum: AggregateResult) => {
        return <Cell align="start" value={datum.rank.toString()} />
      }
    },
    {
      property: "name",
      header: undefined,
      render: (datum: AggregateResult) => {
        return <Cell value={datum.name} align="start" />
      }
    },
    {
      property: "score",
      header: undefined,
      render: (datum: AggregateResult) => {
        return <Cell align="center" value={numberWithCommas(datum.score)} />
      }
    }
  ]
}


// const gameRankColumns = [
//   {
//     property: "rank",
//     header: "Rank",
//     primary: true
//   },
//   {
//     search: true,
//     property: "name",
//     header: "Name",
//   },
//   {
//     property: "average",
//     header: "Average Score",
//     render: (datum: AggregateResult) => Math.round(datum.average)
//   },
//   {
//     property: "max",
//     header: "Best Score",
//     render: (datum: AggregateResult) => Math.round(datum.max)
//   }
// ] as ColumnConfig<AggregateResult>[]