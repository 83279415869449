import React from 'react'
import { Box, Text } from 'grommet'
import { Game } from '../datamodel'
import Countdown from './Countdown'
import styled from 'styled-components';

interface Props {
  game: Game
  onTimerComplete: () => void
}


function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const Header = styled(Text).attrs({
  level: 3,
  textAlign: 'center',
  size: 'large'
})`

  font-weight: bold;
`

const ContentContainer = styled(Box).attrs({
  margin: { top: 'small', bottom: 'small' }
})``
export default function GameState({ game, onTimerComplete }: Props) {
  return <>
    <ContentContainer>
      <Header>Round:</Header>
      <Header>{`${game.round}`}</Header>
    </ContentContainer>

    <ContentContainer>
      <Header>Score:</Header>
      <Header>{numberWithCommas(game.score)}</Header>
    </ContentContainer>
    <ContentContainer>
      <Header>Time:</Header>
      <Countdown size="large" textAlign="center" weight="bold" key={game.round} initialTime={10} onComplete={onTimerComplete} />
    </ContentContainer>
  </>
}