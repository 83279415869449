import _ from 'lodash';
import Tile from './Tile';
import Colors from '../../../utils/Color';

/**
 * A game is made up of 10 boards.
 *
 * Each board consists of
 *   - a number of tiles
 *   - a timer
 *   - the tile that has a different color
 *
 * Each tile consists of a color
 *
 */

const MAX_ROUNDS = 30;

export default class Board {
  static y = MAX_ROUNDS;
  static DifficultyMultiplier = 7;

  tiles: ReadonlyArray<Tile>;

  diff: Tile[];

  constructor(tileCount: number, round: number) {
    this.diff = [];
    const tiles = new Array(tileCount);

    // random generate a color
    const hardColorsOnly = round / Board.y > 0.66;
    const hex = Colors.random(hardColorsOnly);
    for (let i = 0; i < tileCount; i++) {
      tiles[i] = new Tile(hex, i);
    }

    const { y, DifficultyMultiplier } = Board;
    const differingHex = Colors.calcColorDiff(
      hex,
      round,
      y,
      DifficultyMultiplier,
    );
    if (differingHex !== hex) {
      const index = _.sample([...Array(tileCount - 1).keys()]) as number;
      const tile = new Tile(
        Colors.calcColorDiff(hex, round, y, DifficultyMultiplier),
        index,
      );

      console.log('bad_tile_at', index);
      this.diff.push(tile);
      tiles[index] = tile;
    }

    this.tiles = tiles;
  }

  get columnsCount() {
    return Math.sqrt(this.tiles.length);
  }

  get rowCount() {
    return Math.sqrt(this.tiles.length);
  }
}
