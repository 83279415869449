import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Image,
  Grid,
  Text,
  Layer
} from "grommet";

import Suggestions from './components/Suggestions'
import Countdown from "./components/Countdown";
import { ColorCardGrid } from "./components/ColorCardGrid";
import { Game, Tile } from "./datamodel";
import Configuration from "./components/GameConfiguration";
import GameStats from "./components/GameStats";
import {
  useHistory,
  RouteChildrenProps,
  useLocation,
  useRouteMatch,
} from "react-router";

import { useCurrentEmail } from "../../utils/hooks";
import firebase from "../../firebase";
import { User } from "../../models";

interface State {
  gameID: string;
  round: number;
  showHint: boolean
}
interface GamePlayProps extends RouteChildrenProps {
  user: User;
}
class GamePlay extends React.Component<GamePlayProps, State> {
  game: Game;

  constructor(props: GamePlayProps) {
    super(props);

    this.game = new Game(props.user);
    this.state = {
      showHint: false,
      gameID: this.game.id,
      round: this.game.round,
    };
  }

  componentDidMount() {
    this.game.play();
  }

  onCardClick = async (tile: Tile | null) => {
    console.log('clicked on position', tile?.position)
    let userChoices = [];
    if (tile) {
      userChoices.push(tile);
    }

    const isCorrectAnswer = this.game.checkAnswer(...userChoices);
    if (!isCorrectAnswer) {
      this.onFail();
    } else {
      if (this.game.advance()) {
        this.setState({ round: this.game.round });
      } else {
        await this.game.end();

        const { history, location } = this.props;
        history.replace(`/leaderboard${location.search}`);
      }
    }
  };

  onTimerComplete = () => {
    if (this.game.hints === 0) {
      this.onFail();
    }
  };

  onFail = () => {
    if (this.game.hints > 0) {
      this.setState({ showHint: true })
      return
    }

    console.log("onFail");
    this.game.end();

    const { history, location } = this.props;
    history.replace(`/leaderboard${location.search}`);
  };

  onHintClick = () => {
    this.setState({ showHint: false })

    const tiles = this.game.consumeHint();
    this.onCardClick(tiles[0]);
  }


  render() {
    // find minimum 100vh > 100vw and use the value as the maxWidth and maxHeight
    const { showHint } = this.state
    const width = 1024;
    const { game } = this;
    const { rowCount, columnsCount, tiles } = game.board;
    return (
      <Box overflow="auto" height={{ min: '100vh' }} width={{ min: '100vw' }} align="center">
        {showHint && <HintModal onClick={this.onHintClick} remaining={game.hints} />}
        <Box style={{ minHeight: "90vh", maxHeight: "100vh" }}>
          <Heading level={3}>Which Color is Different?</Heading>
          <Card
            background="light-2"
            width={`${width}px`}
            style={{ maxWidth: "100vw", maxHeight: "100vh" }}
          >
            <CardHeader background="accent-2">
              <Box
                margin={{ left: "medium", right: "medium" }}
                direction="row"
                fill
                justify="between"
              >
                <GameStats game={game} onTimerComplete={this.onTimerComplete} />
              </Box>
            </CardHeader>
            <CardBody
              style={{
                height: width, maxWidth: "100vw", maxHeight: "90vh",
              }}
            >


              <Grid
                style={{
                  width,
                  height: width,
                  maxHeight: "50vh",
                  maxWidth: "100vw",
                  gridAutoFlow: "column",
                  gridTemplateRows: `repeat(${rowCount}, 1fr)`,
                  gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
                }}
                gap="small"
                pad="medium"
                responsive
              >
                <ColorCardGrid
                  key={game.round.toString()}
                  onItemClick={this.onCardClick}
                  tiles={tiles}
                />
              </Grid>

              <Button
                size="large"
                primary
                label="No Color Differences"
                margin="medium"
                onClick={() => {
                  this.onCardClick(null);
                }}
              />
            </CardBody>
          </Card>
        </Box>

        <Box
          height={{ min: "medium", max: "large" }}
          pad={{ bottom: "medium" }}
        >
          <Image
            style={{ borderRadius: 12 }}
            src="https://s3.amazonaws.com/colorcloud.io/product-images/csmp_ad.png"
            fit="contain"
          />
        </Box>
      </Box >
    );
  }
}

function HintModal({ onClick, remaining }: { onClick: () => void, remaining: number }) {
  const handleClick = React.useCallback(() => {
    if (remaining === 0) {
      return
    }

    onClick()
  }, [remaining, onClick])


  return <Layer onEsc={handleClick} onClickOutside={handleClick} >
    <Card
      animation="slideDown"
      direction="row"
      style={{ position: 'relative' }}
      height="small"
      width="large"
      background="white">
      <Box
        round="large"
        animation="slideDown"
        onClick={handleClick}
        width="100%"
        background="white"
        direction="row"
      >
        <Box width="small" height="small" pad="4px" background="accent-3">
          <Image fit="contain" src="https://s3.amazonaws.com/colorcloud.io/product-images/colorsnap_match.png" width="small" height="small" />
        </Box>
        <Box pad="small" background="white" height="small" width="large">
          <Text color="accent-2" weight="bold" size="large" >Need a hand? </Text>
          <Text color="accent-2">Utilize the color matching power of Color Snap Match Pro to keep playing.</Text>


          <Box style={{ position: 'absolute', right: 16, bottom: 0 }} justify="center" round="small" margin="medium" pad="small" background="accent-3" width="small" height="xxsmall">
            <Text textAlign='center'> Use Color Snap Match Pro </Text>
          </Box>
        </Box>
      </Box>
    </Card>
  </Layer>
}
export default function GameWithCountdown() {
  const [isUserPlaying, setPlaying] = React.useState(false);
  const [userDoc, setUserDocument] = React.useState<User | null>(null);
  const email = useCurrentEmail();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  React.useEffect(() => {
    // redirect page without a user.
    if (!email) {
      history.replace("/");
      return;
    }

    firebase.lookupEmail(email).then((doc) => {
      if (!doc) {
        alert("Please register before playing");
        history.replace("/");
        return;
      }

      setUserDocument(doc);
    });
  }, [email, history]);

  if (!isUserPlaying) {
    return (
      <Box margin="auto" align='center'>
        <Configuration />

        <Countdown
          margin={{ bottom: 'large' }}
          textAlign="center"
          animate
          weight="bold"
          size="500%"
          initialTime={5}
          onComplete={() => setPlaying(true)}
        />

        <Suggestions />
      </Box>
    );
  }

  if (!userDoc) {
    return (
      <Box margin="auto" align="center" >
        <Configuration />
        <Countdown
          textAlign="center"
          animate
          weight="bold"
          size="500%"
          initialTime={5}
          onComplete={() => setPlaying(true)}
        />
      </Box>
    );
  }

  return (
    <GamePlay
      match={match}
      location={location}
      history={history}
      user={userDoc}
    />
  );
}

