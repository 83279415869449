import React from 'react'
import { useLocation } from 'react-router'
import qs from 'query-string'

import Input from '../../Home/Registration/components'
import { Board } from '../datamodel'

export default function Configuration() {
  const location = useLocation()
  const [, setMaxRounds] = React.useState(Board.y)
  const [, setDifficulty] = React.useState(Board.DifficultyMultiplier)

  const { c } = qs.parse(location.search);
  if (!c) {
    return null;
  }
  return <div>
    <Input
      type="number"
      label="Max Rounds"
      placeholder=""
      value={Board.y}
      onChange={e => {
        Board.y = parseInt(e.target.value)
        setMaxRounds(Board.y)
      }} />
    <Input
      type="number"
      label="Difficulty"
      placeholder=""
      value={Board.DifficultyMultiplier}
      onChange={e => {
        Board.DifficultyMultiplier = parseInt(e.target.value)
        setDifficulty(Board.DifficultyMultiplier)
      }} />

  </div>
}