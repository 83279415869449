import _ from 'lodash';
import moment from 'moment';

import { Tile } from '.';
import { User } from '../../../models';
import Board from './Board';
import firebase from '../../../firebase';

const MAX_WIDTH = 6;
const ROUND_TIME_SECONDS = 10;
const MAX_ROUNDS = 10;
function calculateTileCount(round: number): number {
  return Math.min(MAX_WIDTH, round + 1) * Math.min(MAX_WIDTH, round + 1);
}

export default class Game {
  static TotalHints = 1;
  static get MaxRounds() {
    return Board.y;
  }

  hints: number;
  board: Board;

  round: number;

  private roundStartTime: number | undefined;

  readonly user: User;
  readonly id: string;

  score: number = 0;

  constructor(user: User) {
    this.hints = Game.TotalHints;

    this.id = _.uniqueId('game_');

    this.user = user;

    this.round = 1;
    this.board = new Board(calculateTileCount(this.round), this.round);
  }

  play() {
    this.roundStartTime = moment.now();
  }

  checkAnswer(...selection: Tile[]): boolean {
    //TODO: When moving to multiple difference
    return _.isEqual(selection, this.board.diff);
  }

  advance(): boolean {
    if (!this.roundStartTime) {
      console.error('game broke');
      return false;
    }

    this.calculateScore(moment.now() - this.roundStartTime);

    this.round++;
    this.board = new Board(calculateTileCount(this.round), this.round);

    if (this.round === Game.MaxRounds) {
      return false;
    }

    this.play();
    return true;
  }

  consumeHint(): Tile[] {
    if (this.hints <= 0) {
      return [];
    }
    this.hints--;

    return [...this.board.diff];
  }

  end() {
    //TODO: store results in new collection
    return firebase.saveGameResult(this);
  }

  private calculateScore(elapsedTimeMillis: number) {
    const remaining = ROUND_TIME_SECONDS * 1000 - elapsedTimeMillis;
    const time_score = remaining + (this.round / MAX_ROUNDS) * 0.5 * remaining;
    const round_score = this.round * 5 + 100;

    this.score += round_score + time_score;
    this.score = Math.round(this.score);
  }
}
