import React from "react";
import styled from 'styled-components'
import { Box, BoxTypes } from "grommet";
import theme from '../../theme'

const spinning = (
  <svg
    version="1.1"
    viewBox="0 0 32 32"
    width="96px"
    height="96px"
    fill="#fff"
  >
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

export const Spinner = () => (
  <Box align="center" justify="center">
    {spinning}
  </Box>
);


interface TabProps extends BoxTypes {
  selected?: boolean
}
type BoxComponent = React.FC<TabProps & JSX.IntrinsicElements['div']>
export const Tab = styled<BoxComponent>(Box).attrs({
  align: 'center',
  pad: 'small',
  margin: 'small'
})`
  
  flex: 1;

  ${({ border, selected }) => {
    if (border || selected) {
      return `
        background: white;
        border-radius: 15% 15% 15% 15% / 90% 90% 90% 90%;
        border-color: transparent;
        border: none;
        color: ${theme.global.colors['dark-1']};
      `
    }

    return ``
  }}

  :hover {
    cursor: pointer;
  }
  
`

export const Tabs = styled<BoxComponent>(Box).attrs({
  fill: true,
  background: 'dark-2'
})`
  
  flex-direction: row;
  align-items: center;
 
  border-radius: 5% 5% 5%  5% / 75% 75% 75% 75%;
  border-color: transparent;
  border: none;
`
