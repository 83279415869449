import * as React from "react";
import { Box, Image, Heading } from "grommet";
import { Text } from "../common";
import Registration from "./Registration";

export default React.memo(() => {
  return (
    <>
      <Box height="72px">
        <Image
          margin="auto"
          width="100%"
          height="48px"
          src="https://s3.amazonaws.com/colorcloud.io/product-images/Variable_logo_alt_01.png"
          fit="contain"
        />
      </Box>
      <Heading margin={{ top: "large" }}>
        Who shall prevail: Man or machine?
      </Heading>
      <Text size="large" style={{ lineHeight: 1.5 }}>
        Compete against your fellow distributors to see which of you has the best eye for color detail! Grand prize is a set of Apple AirPods (3rd Gen)
      </Text>

      <div style={{ height: "33vh", width: "100%", background: 'white' }}>
        <Image
          width="100%"
          height="100%"
          src="https://s3.amazonaws.com/colorcloud.io/product-images/airpods.jpg"
          fit="contain"
        />
      </div>

      <Registration />
    </>
  );
});
