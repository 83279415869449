import React from 'react'
import _ from 'lodash'
import { Card, Box, Image, Text, CardBody } from 'grommet'
import suggestions from './data'


export default React.memo(() => {
  const item = _.sample<{ title: string, descr: string }>(suggestions.items)
  if (item == null) {
    return null;
  }
  return <Card
    animation="slideDown"
    align="center"
    justify="center"
    direction="row"
    height="small"
    width="large"
    background="white">

    <CardBody direction="row">
      <Box width="small" height="small" pad="8px" background="accent-3">
        <Image fit="contain" src="https://s3.amazonaws.com/colorcloud.io/product-images/colorsnap_match.png" width="small" height="small" />
      </Box>
      <Box pad="small" justify="center">
        <Text color="accent-2" weight="bold" size="large" >{item.title}</Text>
        <Text color="accent-2">{item.descr}</Text>
      </Box>
    </CardBody>
  </Card >
})