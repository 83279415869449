import React from 'react'
import { TextInput, Box, Text, SelectProps, Select, TextInputProps } from 'grommet'


interface InputProps extends TextInputProps {
  label: string
  onChange: (e: any) => void
  type: "email" | "password" | "text" | "number"
}

export default function Input({ label: text, ...props }: InputProps) {
  return (
    <Box>
      <Text as="label" size="small" weight="bold" color="light-1" margin={{ bottom: "xsmall" }}>{text}</Text>
      <TextInput id={text.toLowerCase().replace(' ', "_") + "_id"} {...props} style={{ background: '#2c3b44' }} />
    </Box>
  );
}


interface DropdownProps extends SelectProps {
  label: string
}
export function Dropdown({ label, ...props }: DropdownProps) {
  return (
    <Box>
      <Text size="small" weight="bold" margin={{ bottom: 'xsmall' }} color="light-1">{label}</Text>
      <Box background="#2c3b44">
        <Select  {...props} />
      </Box>
    </Box>
  )
}